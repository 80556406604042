import { Button, notification, Typography } from 'antd';
import React, { useState } from 'react';
import AccountLayout, {
  BASE_ACCOUNT_PATH,
  PASSWORD_SETTING_PATH,
} from './AccountLayout';
import Breadcrumb from '../Breadcrumb';
import MainLayout from '../layout/MainLayout';
import Section from '../Section';
import { useCustomer } from '../../hooks/use-customer';
import CloseCircle from '../../icons/close-circle';
import { navigate } from 'gatsby';

const { Text } = Typography;

const PasswordSettingPage = () => {
  const {
    actions: { requestResetPassword },
  } = useCustomer();
  const [loading, setLoading] = useState(false);
  const onReset = async () => {
    try {
      setLoading(true);
      await requestResetPassword();
      navigate('/forgot-password/instruction-sent');
      // TODO: reset password api
    } catch (_) {
      notification.open({
        key: 'reset-password-error',
        closeIcon: <></>,
        message: (
          <div className="flex">
            <div className="!text-primary-6 mt-1 mr-2">
              <CloseCircle />
            </div>
            <span>
              พบข้อผิดพลาดในการรีเซ็ตรหัสผ่านของคุณ กรุณาลองใหม่อีกครั้ง
            </span>
          </div>
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'บัญชีของฉัน',
            link: BASE_ACCOUNT_PATH,
          },
          {
            title: 'ตั้งค่ารหัสผ่าน',
            link: PASSWORD_SETTING_PATH,
          },
        ]}
        title="ข้อมูลส่วนตัว"
      />
      <Section className="py-6">
        <AccountLayout>
          <div className="p-6 bg-neutral-1 rounded-[6px] shadow-small border-light-divider border flex flex-col">
            <Text className="!text-light-title !font-medium mb-6">
              ลืมรหัสผ่าน?
            </Text>
            <Button
              type="primary"
              onClick={onReset}
              loading={loading}
              className="w-fit"
            >
              รีเซตรหัสผ่าน
            </Button>
          </div>
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

export default PasswordSettingPage;
